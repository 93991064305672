<template>
<div>Billing</div>
</template>

<script>
export default {
  name: 'AccountBilling'
}
</script>

<style scoped>

</style>
